$header-height: em(630);
.z-header {
  @include size(100% $header-height);
  @include padding(0 0 0 0);
  @include position(relative);
  @include background-image(url("/login-bg.jpeg"));
  background-repeat: no-repeat;
  background-size: cover;

  header {
    position: absolute;
    top: 30%;
    display: block;
    text-align: center;
    color: white;
    @include size(calc(100%) $header-height);
    h1 {
      display: block;
      font-size: em(100);
    }
    p {
      font-size: em(28);
    }
    @media screen and (max-width: 460px) {
      top: 20%;
      h1 {
        font-size: em(40);
      }
      p {
        font-size: em(22);
      }
    }
    @media screen and (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      h1 {
        font-size: em(68);
      }
    }
  }
}

/*==========2. MAIN FEATURES==============*/
.mainFeatures {
  margin-top: 50px;
}
.thumbnail {
  border: 0;
  padding: 20px 0;
  box-shadow: none;
  text-align: center;
  background: transparent;
}
.highlight {
  font-weight: 900;
  color: #22bb5f;
}

/*==========3. MORE FEATURES==============*/
.PageHead {
  margin-top: 50px;
  text-align: center;
  h3 {
    color: #666;
    font-weight: normal;
  }
}
.features {
  margin: 25px 0;
  float: left;
  img {
    float: left;
    padding: 10px 20px 0 0;
  }
}
#map_canvas {
  height: 300px;
}

/*==========4. FEATURES LAYOUT==============*/
.FeatLayout {
  margin-top: em(50px);
  .Featimg {
    text-align: center;
  }
  h3 {
    color: #666;
    font-weight: normal;
  }
}
.ticklist {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    width: 50%;
    float: left;
    padding: 5px 5px 5px 30px;
    background: url('//zaplee.com/images/tick.png') no-repeat left;
  }
}
.partnersList img {
  max-height: 60px;
  margin: 0 auto;
  @media screen and (max-width: 720px ) {
    margin-top: em(20);
  }
}
