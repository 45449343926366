
/*==========9. CONTACT ==============*/
.ContactUs {
  margin-top: 25px;
}
.ContactUs input[type=text],
.ContactUs input[type=email] {
  height: 50px;
}
.ContactUs textarea {
  height: 150px;
}
.ContactUs .btn {
  width: 100%;
  margin: 5px 0;
}
