/*
    Navbar
*/
.navbar {
  background: $zapBlue;
}
.navbar-nav,
.navbar-header {
  padding: 10px 0;
}
.navbar-nav {
  padding-right: 10px;
}
.navbar-brand {
  margin-top: -10px;
}
.navbar-default .navbar-toggle.collapsed {
  background: rgb(35, 42, 49);
}
.icon-bar {
  background-color: #fff;
}
.navbar .nav li a {
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 1px 0 #666;
  @include transition(all 0.3s ease-in);
  &:focus,
  &:hover {
    background-color: $zapBlue;
    color: #9e9e9e;
  }
}
.navbar .nav > .active > a {
  background: none;
  color: #2cc76a;
  box-shadow: none;
  &:hover,
  &:focus {
    color: darken(#2cc76a, 10%);
  }
}

.hamburger {
  position: relative;
  display: inline-block;
  width: 1.25em;
  height: 0.8em;
  margin-right: 0.3em;
  border-top: 0.2em solid #fff;
  border-bottom: 0.2em solid #fff;

  &:before {
    content: "";
    position: absolute;
    top: 0.3em;
    left: 0;
    width: 100%;
    border-top: 0.2em solid #fff;    
  }
}
