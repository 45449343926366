$pad: 10px;

.pt {
  padding-top: 10px;
}

.pt-xl {
  padding-top: $pad * 2;
}

.pt-xxl {
  padding-top: $pad * 4;
}
