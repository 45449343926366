/*==========5. TESTIMONIALS==============*/
.Testimonials {
  p {
    font-style: italic;
  }
  .thumbnail {
    img {
      border: 100%;
    }
    h5 {
      text-align: center;
    }
  }
}
.PartnersList {
  text-align: center;
}
/* Animation */
.Testimonials .col-sm-4 {
  @include transition(all 0.6s ease);
  &:hover {
    @include transform(scale(1.05));
    z-index: 2;
  }
}
