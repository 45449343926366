/*==========0. GLOBAL STYLES==============*/

body {
  font-family: $baseFont;
  background: #fafafa;
}
a {
  color: $zapGreen;
  &:hover,
  &:focus {
    color: #1eb45a;
  }
}

.hero-unit {

  @media screen and (max-width: 460px) {
    padding: 0 em(15);
    padding-top: em(60px) !important;

    .btn-zaplee {
      font-size: em(16);
      padding: em(20) em(25);
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: em(52);
    }
  }
}
