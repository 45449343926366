body > nav > div > div.navbar-collapse.collapse > ul > li a{ font-weight: 500 !important; letter-spacing: 0.1em;}
/*
    Buttons
*/
.btn-large {
  font-size: 18px;
  padding: 20px 50px;
  margin: 5px 10px;
}
.btn-success {
  background-color: #2cc76a;
  background-image: none;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  &:hover,
  &:focus,
  &:active {
    background-color: #22bb5f!important;
    color: #FFF!important;
  }
}
.btn-primary {
  background-color: #2db6d8;
  background-image: none;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  &:hover,
  &:focus,
  &:active {
    background-color: #209ab8!important;
    color: #FFF!important;
  }
}
.btn-danger {
  background-color: #f45c57;
  background-image: none;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  &:hover,
  &:focus,
  &:active {
    background-color: #eb534e!important;
    color: #FFF!important;
  }
}

.navbar .nav > .active > a.btn {
  background-color: #22bb5f!important;
  color: #FFF!important;
}

.btn-zaplee {
  background-color: #0D7AA9;
  color: #fafafa;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  @include transition(all 350ms ease);

  &:hover,
  &:focus,
  &:active {
    background-color: darken(#0D7AA9, 10%);
    color: #FFFFFF;
  }

}
    //       style="letter-spacing:0.15em; color:#fefefe; font-weight:700; max-width: 370px; overflow:hidden;"
