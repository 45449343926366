/*==========10. FOOTER ==============*/
.footer {
  position: relative;
  color: #FFFFFF;
  background: $footer-bg;
  background-size: cover;
  overflow: hidden;
  margin-top: 80px;
}

.footerlinks {
  margin: 20px 0;
  p {
    color: lighten(#68737b, 20%);
    text-transform: uppercase;
    padding-top: 15px;
  }
  ul {
    @include margin(0 0 0 0);
    @include padding(0 0 0 0);
    list-style-type: none;
  }
  li a {
    color: #FFFFFF;
  }
}

.copyright {
  margin: 30px 0;
}
