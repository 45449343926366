/*==========1. HEADER==============*/
.jumbotron {
  position: relative;
  color: #fff;
  background: url("//zaplee.com/images/header-bg.jpg") no-repeat #232a31;
  max-width: 100%;
  @include size(100% em(630));
  background-size: cover;
  overflow: hidden;
  @include clearfix;
}
.btn-center {
  margin: auto;
}

// Hero Unit
.hero-unit {
  background: none;
  text-align: center;
  padding-top: em(130);

  h1 {
    max-width: 95%;
  }

  h1, h3 {
    padding-bottom: em(10);
  }
  h3 {
    font-weight: normal;
  }
}
