/*==========6. PLANS AND PRICING==============*/
/* Animation */
.PlanPricing {
  margin-top: 100px;
  @include transition(all 0.6s ease);
  &:hover {
    @include transform(scale(1.05));
    z-index: 2;
  }
  p {
    margin: 0;
  }
  .btn {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
  .planName {
    background: #202b34;
    padding-top: 50px;
    padding-bottom: 10px;
    text-align: center;
    min-height: 100px;
    h3 {
      color: #fff;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
    p {
      color: #999;
    }
  }
  .price {
    background: #2cc76a;
    padding: 5px;
    color: #fff;
    font-size: 40px;
    border-radius: 100%;
    display: block;
    @include size(100px);
    @include margin(-107px auto 10px auto);
    line-height: 100px;
    position: relative;
  }
  .planFeatures {
    background: #e8e9ea;
    text-align: center;
    padding: 10px;
    min-height: 224px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    li {
      padding: 13px 0;
    }
  }
}

// -----------
//  Removed For New Pricing Model
//.PlanPricing.Recommended .price {
//	background:#2db6d8;
//}
// ----------
/*==========7. COMPARE PLANS ==============*/
.ComparePlans {
  margin-top: 20px;
  .planFeatures {
    text-align: center;
    padding: 10px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        padding: 13px 0;
      }
    }
  }
  .planHead1,
  .planHead2,
  .planHead3 {
    font-size: 60px;
  }
  .planHead2,
  .planHead3 {
    h3 {
      color: #fff;
      margin: 0;
      padding: 10px 0 0;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .planHead1 {
    background: #fefefe;
  }
  .planHead2 {
    background: #2cc76a;
  }
  .planHead3 {
    background: #f45c57;
  }
  .btn {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
}
.ComparePlans .CompareList .planFeatures {
  text-align: right;
}
.ComparePlans .pricing1 .planFeatures {
  border-right: solid 1px #2cc76a;
  border-left: solid 1px #2cc76a;
}
.ComparePlans .pricing2 .planFeatures {
  border-right: solid 1px #f45c57;
  border-left: solid 1px #f45c57;
}
